import React from 'react'
import { Link } from 'gatsby'
// import { Title } from "../Core";
import logo from '../../assets/image/svg/b.svg'
const Logo = ({ color = 'front', height, className = '', ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      <img src={logo} style={{ width: '40px', fill: '#000' }} />
      {/* <Title color={color} variant="cardLg" className="mb-0">
        folio.
      </Title> */}
    </Link>
  )
}

export default Logo
