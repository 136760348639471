import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'

import { Section, Title, Text, Span } from '../../components/Core'
import imgPhoto from '../../assets/image/svg/PHOTO-2022-11-02-14-23-45 2 1.svg'

const LinkSocial = styled.a`
  color: ${({ theme }) => theme.colors.secondary} !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 1.63px;
  transition: 0.4s;
  &:hover {
    color: ${({ theme }) => theme.colors.secondary} !important;
    transform: translateY(-5px);
  }
`

const About = ({ hero = true, ...rest }) => {
  return (
    <>
      <Section hero={hero} {...rest}>
        <Container>
          <Row className="align-items-center">
            <Col lg="6">
              <div>
                <img src={imgPhoto} alt="folio" className="img-fluid" />
              </div>
            </Col>
            <Col lg="6">
              <div className="pl-lg-4 pt-5 pt-lg-0">
                <Title variant="secSm">
                  Bernadetta Nycz
                </Title>
                <Text

                  className="mt-3 mt-lg-5"
                  css={`
                    line-height: 1.5;
                  `}
                >
                  I have a background in design and fashion which gives me a
                  strong sense of visual storytelling combined with basic
                  front-end skills, and a good understanding of modern
                  technologies. I love collaborating with developers & creatives
                  to grow a diverse tech community.
                </Text>
                <Text className="mt-4">
                  I strive for a minimal and beautiful design.
                </Text>
                <div className="mt-4">
                  <Text>Email me at</Text>

                  <Text variant="p">
                    <a
                      href="mailto:beta.nycz@icloud.com"
                      className="font-weight-bold"
                    >
                      <Span>beta.nycz@icloud.com</Span>
                    </a>
                  </Text>
                </div>
                {/* <div className="mt-5">
                  <img src={imgSignLight} alt="sign" className="img-fluid" />
                </div>
                <Text className="mt-3 font-weight-bold">
                  Bruce Ryan
                </Text>
                <Text variant="small">
                  Founder of Folio Digital
                </Text>
                <div className="mt-5 d-flex">
                  <LinkSocial
                    href="https://www.dribbble.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    Dribble
                  </LinkSocial>
                  <LinkSocial
                    href="https://www.twitter.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" mr-3"
                  >
                    Twitter
                  </LinkSocial>
                  <LinkSocial
                    href="https://www.facebook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    Facebook
                  </LinkSocial>
                  <LinkSocial
                    href="https://www.instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-weight-bold"
                  >
                    Instagram
                  </LinkSocial>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default About
