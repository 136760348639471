import { rgba } from 'polished'
import { breakpoints } from './breakpoints'

// change bg at workcard overlay in light mode to
// background - image: linear - gradient(


// radial - gradient(circle, rgb(246, 240, 229) 0 %, rgb(204 204 255) 60 %, rgb(195, 198, 254) 94 %)!important
const defaultColors = {
  primary: '#7A60ED', //effects links, toggle (partially)and about me page bg
  secondary: '#EFEAEA',
  white: '#f5f5f7',
  dark: '#111',
  ash: '#01DCBA',
  black: '#1c11',
  warning: '#fedc5a',
  success: '#7A60ED',
  info: '#482EC3',
}

//light theme values
const colors = {
  primary: defaultColors.primary,
  secondary: defaultColors.secondary,
  light: defaultColors.white,
  lightShade: rgba(defaultColors.white, 0.5),
  dark: defaultColors.dark,
  darkShade: rgba(defaultColors.dark, 0.5),
  ash: defaultColors.ash,
  // bg: 'radial-gradient(150% 150% at 50% 20%, transparent 40%,#4133ee 100%, #fff 0)',
  bg: 'radial-gradient(circle, rgb(246, 240, 229) 0 %, rgb(204 204 255) 60 %, rgb(195, 198, 254) 94 %)!important', bgSolid: 'black',
  // bg: 'radial-gradient(circle,  rgba(241, 241, 241, 1) 60%, rgba(240, 236, 255, 1) 56%, rgba(195, 198, 254, 1) 94%)',
  // background: rgb(0, 0, 0);
  // background: radial - gradient(circle, rgba(0, 0, 0, 0.1012998949579832) 0 %, rgba(61, 37, 79, 0.2581626400560224) 40 %, rgba(51, 0, 255, 1) 100 %);
  // background: radial - gradient(circle, rgba(235, 240, 229, 1) 0%, rgba(241, 241, 241, 1) 60%, rgba(195, 198, 254, 1) 94%);
  headerBg: 'radial-gradient(circle, rgb(220, 223, 254),  rgba(230,226,266,0.7))',
  bgSolid: defaultColors.white,
  bgShade: rgba(defaultColors.white, 0.5),
  front: defaultColors.dark,
  frontShade: rgba(defaultColors.dark, 0.5),
  border: rgba(defaultColors.ash, 0.115),
  shadow: rgba(defaultColors.ash, 0.175),
  heading: defaultColors.dark,
  text: rgba(defaultColors.dark, 0.7),
  warning: defaultColors.warning,
  success: defaultColors.success,
  info: defaultColors.info,
  inverse: defaultColors.black,


  // dark theme values
  modes: {
    dark: {
      primary: defaultColors.primary,
      secondary: defaultColors.secondary,
      light: defaultColors.white,
      lightShade: rgba(defaultColors.white, 0.5),
      dark: defaultColors.dark,
      darkShade: rgba(defaultColors.dark, 0.5),
      ash: defaultColors.ash,
      bg: 'linear-gradient(274deg,#170F22 18.17%, #84919fad 211.01%)', bgSolid: 'black',
      headerBg: 'linear-gradient(274deg,#170F22 18.17%, #84919fad 211.01%)', bgSolid: 'black',
      bgShade: rgba(defaultColors.dark, 0.5),
      front: defaultColors.white,
      frontShade: rgba(defaultColors.white, 0.5),
      border: rgba(defaultColors.ash, 0.115),
      shadow: rgba(defaultColors.ash, 0.5),
      heading: defaultColors.white,
      text: rgba(defaultColors.white, 0.7),
      warning: defaultColors.warning,
      success: defaultColors.success,
      info: defaultColors.info,
      inverse: defaultColors.white
    },
  },
}

const theme = {
  initialColorModeName: 'light',
  colors: colors,
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: [
    `${breakpoints.sm}px`,
    `${breakpoints.md}px`,
    `${breakpoints.lg}px`,
    `${breakpoints.xl}px`,
  ],
}

export default theme
