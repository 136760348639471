// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-berlincalling-details-js": () => import("./../../../src/pages/berlincalling-details.js" /* webpackChunkName: "component---src-pages-berlincalling-details-js" */),
  "component---src-pages-berlinomania-details-js": () => import("./../../../src/pages/berlinomania-details.js" /* webpackChunkName: "component---src-pages-berlinomania-details-js" */),
  "component---src-pages-brainy-details-js": () => import("./../../../src/pages/brainy-details.js" /* webpackChunkName: "component---src-pages-brainy-details-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cryptoapp-details-js": () => import("./../../../src/pages/cryptoapp-details.js" /* webpackChunkName: "component---src-pages-cryptoapp-details-js" */),
  "component---src-pages-dzemo-details-js": () => import("./../../../src/pages/dzemo-details.js" /* webpackChunkName: "component---src-pages-dzemo-details-js" */),
  "component---src-pages-freestep-details-js": () => import("./../../../src/pages/freestep-details.js" /* webpackChunkName: "component---src-pages-freestep-details-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inspirers-details-js": () => import("./../../../src/pages/inspirers-details.js" /* webpackChunkName: "component---src-pages-inspirers-details-js" */),
  "component---src-pages-kikimora-details-js": () => import("./../../../src/pages/kikimora-details.js" /* webpackChunkName: "component---src-pages-kikimora-details-js" */),
  "component---src-pages-mtor-details-js": () => import("./../../../src/pages/mtor-details.js" /* webpackChunkName: "component---src-pages-mtor-details-js" */),
  "component---src-pages-portfolio-details-js": () => import("./../../../src/pages/portfolio-details.js" /* webpackChunkName: "component---src-pages-portfolio-details-js" */),
  "component---src-pages-studiod-b-details-js": () => import("./../../../src/pages/StudiodB-details.js" /* webpackChunkName: "component---src-pages-studiod-b-details-js" */),
  "component---src-pages-wave-details-js": () => import("./../../../src/pages/wave-details.js" /* webpackChunkName: "component---src-pages-wave-details-js" */),
  "component---src-pages-youknowmewell-details-js": () => import("./../../../src/pages/youknowmewell-details.js" /* webpackChunkName: "component---src-pages-youknowmewell-details-js" */)
}

