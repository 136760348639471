import React, { useContext } from 'react'
import { rgba } from 'polished'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import { Section, Title, Text, Span, Box } from '../../components/Core'
import ContactForm from '../../components/ContactForm'
import { device } from '../../utils'
import github from '../../assets/image/svg/github.svg'
import githubLight from '../../assets/image/svg/github-light.svg'
import linkedinLight from '../../assets/image/svg/linkedin.svg'
import GlobalContext from '../../context/GlobalContext'
import message from '../../assets/lottie/sendmessge.json'
import Lottie from 'lottie-react'

const ContactCard = styled.div`
  border-radius: 10px;
  background: ${({ theme }) => rgba(theme.colors.primary, 0.9)};
  color: ${({ theme }) => theme.colors.light} !important;
  padding: 1rem;
  ${'' /* margin-top: 1rem; */}
  ${'' /* @media ${device.lg} {
    margin-top:1rem;
  } */}
`

const IconWrapper = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
`

const Contact = ({ hero = true, ...rest }) => {
  const gContext = useContext(GlobalContext)
  return (
    <>
      <Section hero={hero} {...rest}>
        <Container>
          <Row className="justify-content-center">
            <Box className="p-5 ml-lg-1">
              <Title variant="secSm" mb="2rem">
                Contact
              </Title>
              <Text mb="2.75rem">
                Have a project or question? Send me a message.
                <br /> I will reply within 48 hours.
              </Text>
            </Box>
          </Row>
          
          <Row className="justify-content-center">
            <Box className="p-2 ml-lg-1" style={{ 'max-width': '600px'}}>
              <ContactCard className='p-5 ml-lg-1' style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  <Text variant="small" color="light">
                    Email me at
                  </Text>
                  <a
                    href="mailto:beta.nycz@icloud.com"
                    className="font-weight-bold"
                  >
                    <Span color="light">beta.nycz@icloud.com</Span>
                  </a>

                  <div className="mt-5">
                    <Text variant="small" color="light">
                      Find me at
                    </Text>

                    <IconWrapper>
                      <a
                        href="https://github.com/Hiiiij"
                        target="_blank"
                        className="font-weight-bold"
                      >
                        {/* <Span color="primary">+1-402-4983</Span> */}
                        <img src={githubLight} />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/beta-nycz/"
                        target="_blank"
                        className="font-weight-bold"
                      >
                        {/* <Span color="primary">+1-402-4983</Span> */}
                        <img src={linkedinLight} />
                      </a>
                    </IconWrapper>
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <Lottie
                    animationData={message}
                    loop
                    style={{'min-width': '60px'}}
                  />
                </div>
              </ContactCard>
              </Box>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Contact
